/*jshint esversion: 8*/

/*TODO:
   TASKS TO BE DONE:

   - Aref: Handle debug param (?debug=true) in GET /export-actions
   - Aref: Handle debug param (?debug=true) in GET /trigger-actions   
   - Aref: Add debug option to CsTriggers.vue

   + Behzad: Add debug option to CsTriggers.vue
   + Behzad: In CsMenu and exports.vue (Actions tab), make sure that only developers can access.
   + Behzad: Consume this file in Actions.vue and Triggers.vue and Exports (Action tab)

   - App Developer: Staging Lambda functions should pass debug=true query string in order to pick the tasks with debug=true.
*/

/*
* HISTORY: no-version
* V240325.1: Added hasExportAssociation, hasTriggerAssociation and onProduction props to the apps +
   Removed 70001 from hookEventList().
* V240126.1: 1st release.
*/

// List of users who can see Actions and Triggers menus and the Actions tab in the new export creation.
export function developersAccess() {
   return [
      'rmemarian@mindfiremail.info',
      'btalebpour@mindfireinc.com',
      'testuser@mindfiremail.info'
   ];
}

// List of available apps
export function appList() {
   return [
      {
         text: 'Wallet Pass (700)',
         value: '700',
         type: 'outbound',
         typeDesc: 'Outbound',
         componentName: 'KCWalletPassSelector',
         hasExportAssociation: true, //means export can have association with actions of type of walletpass
         hasTriggerAssociation: false, //means trigger can not have association with actions of type of walletpass
         onProduction: true //every users with the proper policy can see
      },
      {
         text: 'Email - Sendgrid (701)',
         value: '701',
         type: 'outbound',
         typeDesc: 'Outbound',
         componentName: 'BtSendgridEmail',
         hasExportAssociation: true,
         hasTriggerAssociation: true,
         onProduction: false //just developers with proper policy can see
      }
   ];
}

// List of the apps events that a trigger can hook to.
export function hookEventList() {
   return [
      { text: 'Wallet Pass: Installed pass', value: '70001' },
      { text: 'Sendgrid: Contact opened Email', value: '70113' },
      { text: 'Sendgrid: Contact clicked a link in Email', value: '70114' },
      { text: 'Sendgrid: Contact opted-out of Email', value: '70115' },
      { text: 'Sendgrid: Contact marked Email as spam', value: '70116' }
   ];
}